import { z } from "zod";

import { TagResponse } from "./tag";

export const AETICLE_COLOR = ["light", "dark"] as const;
export type ArticleColor = (typeof AETICLE_COLOR)[number];

export const ArticleResponse = z.object({
  id: z.string(),
  author: z.string(),
  authorProfile: z.string(),
  body: z.string(),
  color: z.enum(AETICLE_COLOR),
  contents: z.string(),
  description: z.string(),
  headerImageUrl: z.string(),
  isPrivate: z.boolean(),
  publishedAt: z.string(),
  updatedAt: z.string(),
  tags: z.array(TagResponse),
  thumbnailUrl: z.string(),
  title: z.string(),
  urlKeyword: z.string().nullable(),
});
export type Article = z.infer<typeof ArticleResponse>;

export const ArticleResultResponse = z.object({
  data: z.array(ArticleResponse),
  total: z.number(),
});
export type ArticleResult = z.infer<typeof ArticleResultResponse>;

export const PickUpArticleResponse = z.object({
  id: z.string(),
  author: z.string(),
  description: z.string(),
  publishedAt: z.string(),
  tags: z.array(TagResponse),
  thumbnailUrl: z.string(),
  title: z.string(),
  urlKeyword: z.string().nullable(),
});
export type PickUpArticle = z.infer<typeof PickUpArticleResponse>;

export const PickUpResponse = z.object({
  id: z.string(),
  name: z.string(),
  articles: z.array(PickUpArticleResponse),
});
export const PickUpsResponse = z.array(PickUpResponse);
export type PickUp = z.infer<typeof PickUpResponse>;
